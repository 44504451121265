.ic1 {
    width: 100%;
    margin: 4rem 0;
    grid-template-columns: repeat(2, 1fr);
    height: 100%;
}

.ic2 {
    width: 100%;
    margin: 4rem 0;
    grid-template-columns: repeat(2, 1fr);
    display: grid;
    height: 100%;
}

.ic .left {
    text-align: center;
    margin: auto;
    padding: 2rem 4rem;
    max-width: 1200px;
}

.ic .left p {
    margin: 1.0rem 0;
}

.ic .left h2 {
    margin: 1.0rem 0;
    font-size: 0.8rem;
    color: #fff;
    font-weight:400;
}

.ic .left u {
    color: #febe00;
}

.ic .right {
    width: 100%;
}

.left .bar {
    border-bottom: 2px solid #febe00;
    width: 60%;
    margin: 2rem auto;
    display: block;
}

.left .btnbar {
    border-bottom: 2px solid #febe00;
    width: 60%;
    margin: 1rem auto;
    display: block;
}

@media screen and (max-width: 1140px) {
    .ic {
        grid-template-columns: 1fr;
    }

    .ic .img {
        max-width: 80%;
    }

}



