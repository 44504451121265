.focusareas {
    width: 100%;
    padding: 6rem 1rem;
    background-color: #000;
}

.card-container {
    max-width: 1140px;
    margin: auto;
    display: grid;
    grid-gap: 40px;
    grid-template-columns: repeat(3, 1fr);
}

.card-container .card {
    border: 1px solid #eee;
    color: #eee;
}

.card {
    text-align: center;
    padding: 1rem;
}

.card:hover {
    background-color: #141414;
}

.card h3 {
    font-size: 1.2rem;
    padding: 1rem;
}

.card .barbottom {
    border-bottom: 2px solid #ffff;
    width: 40%;
    margin: 1rem auto;
    display: block;
}

.card .bar {
    border-bottom: 2px solid #febe00;
    width: 40%;
    margin: 1rem auto;
    display: block;
}

.card .label {
    font-size: 1.8rem;
    font-weight: 500;
    padding: 1rem;
    color: #febe00
}

.card .pickup {
    font-size: 1.2rem;
    font-weight: 250;
    padding: 0rem;
    color: #febe00;
}

.focusareas p {
    color: #eee;
    padding: 1rem 0;
    font-size: 1rem;
}

.focusareas p1 {
    color: #febe00;
    padding: 1rem 0;
    font-size: 1rem;
    font-weight: 100;
}

.card .btn {
    display: block;
    width: 80%;
    margin: 2rem auto;
    color: #febe00;
}

@media screen and (max-width: 980px) {
    .card-container {
        max-width: 90%;
        margin: auto;
        grid-template-columns: 1fr;
    }
}



