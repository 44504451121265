.au-hero-img {
    background: rgba(0,0,0,.3);
    height: 50vh;
    width: 100%;
    position: relative;
}

.au-hero-img:before {
    content:'';
    background: url('/src/assets/banner-imgs/AUImage.jpg') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.au-hero-img .heading {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.au-hero-img h1 {
    font-size: 2.4rem;
}

.au-hero-img p {
    font-size: 1.4rem;
}

@media screen and (max-width:640px) {
    .au-hero-img h1 {
        font-size: 2rem;
    }
}