.footer {
    width: 100%;
    padding: 3rem 0;
    background: rgba(0,0,0,.8);
    z-index: 6;
}

.footer-container {
    max-width: 1140px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px;
    position: relative;
    z-index: 6;
}

.footer .left {
    height: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    margin-top: 3rem;
}

.footer .right {
    height: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    margin: auto;
}

.footer h4 {
    font-size: 1.0rem;
    padding: 0.5rem 0;
}

.footer p {
    font-size: 1.1rem;
    padding: 1rem 0;
}

.footer .location {
    display: flex;
    align-items: center;
}

.footer .location p {
    padding-bottom: .5rem;
}

.footer .location p2 {
    color: #ffff;
    font-weight: 200;
}

.footer p2 {
    color: #fff;
    padding-bottom: 1rem;
    font-size: 0.8rem;
}

.footer .location h4 {
    padding-top: 0.5rem;
    font-weight: 300;
}

.footer .phone h4 {
    padding-top: 0.5rem;
    font-weight: 300;
}

.footer .email h4 {
    padding-top: 0.5rem;
    font-weight: 300;
}

.footer .right h4 {
    color: #febe00;
}

.footer .right p1 {
    font-size: 0.8rem;
    padding: 1rem 0;
    color: #ffff;
}

@media screen and (max-width:640px) {
    .footer-container {
        grid-template-columns: 1fr;
    }

    .right .social {
        margin: auto;
    }
}






