.au {
    width: 100%;
    margin: 4rem 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: 100%;
}

.au .left {
    text-align: center;
    margin: auto;
    padding: 2rem 4rem;
    max-width: 800px;
}

.au .left p {
    margin: 1.0rem 0;
}

.au .left h2 {
    margin: 1.0rem 0;
    font-size: 0.8rem;
    color: #fff;
    font-weight:400;
}

.au .right {
    width: 100%;
}

.left .bar {
    border-bottom: 2px solid #febe00;
    width: 60%;
    margin: 2rem auto;
    display: block;
}

.left .btnbar {
    border-bottom: 2px solid #febe00;
    width: 60%;
    margin: 1rem auto;
    display: block;
}


.right .img-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    position: relative;
    align-items: center;
    text-align: center;
}

.right .img {
    max-width: 60%;
    border: 1px solid #333;
}

.right .top {
    grid-column: 2/ span 8;
    grid-row: 1;
    padding-top: 20%;
    z-index: 6;
    padding-right: 2rem;
    /*opacity: 0.5;*/
}

.right .bottom {
    grid-column: 4/ 0; 
    grid-row: 1;
    z-index: 5;
    padding-top: 5rem;
}

@media screen and (max-width: 1140px) {
    .au {
        grid-template-columns: 1fr;
    }

    .au .img {
        max-width: 80%;
    }

}





