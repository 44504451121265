.advisorscards {
    width: 100%;
    padding: 0rem 0rem;
    background-color: #000;
}

.acard-container {
    max-width: 1140px;
    margin: auto;
    display: grid;
    grid-gap: 0px;
    grid-template-columns: repeat(2, 1fr);
}

.acard-container .card {
    border: 1px solid #eee;
    color: #eee;
}

.acard {
    text-align: center;
    padding: 1rem;
}

.acard:hover {
    background-color: #141414;
}

.acard h3 {
    font-size: 1.2rem;
    padding: 1rem;
}

.acard .barbottom {
    border-bottom: 2px solid #ffff;
    width: 100%;
    margin: 1rem auto;
    display: block;
}

.acard .bar {
    border-bottom: 2px solid #febe00;
    width: 40%;
    margin: 0rem auto;
    display: block;
}

.acard .label {
    font-size: 1.5rem;
    font-weight: 500;
    padding: 0.2rem;
    color: #febe00
}

.acard .pickup {
    font-size: 1.2rem;
    font-weight: 250;
    padding: 0rem;
    color: #febe00;
}

.advisorscards p {
    color: #eee;
    padding: 0rem 0;
    font-size: 1rem;
}

.advisorscards p1 {
    color: #eee;
    padding: 1rem 0;
    font-size: 1rem;
    font-weight: 250;
}

.acard .btn {
    display: block;
    width: auto;
    margin: 0rem auto;
    color: #febe00;
    text-align: center;
}

@media screen and (max-width: 980px) {
    .acard-container {
        max-width: 90%;
        margin: auto;
        grid-template-columns: 1fr;
    }
}