.insights {
    width: 100%;
    padding: 3rem 1rem;
    background-color: #000;
}

.icard-container {
    max-width: 1000px;
    margin: auto;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(1, 1fr);
}

.icard-container .card {
    border: 1px solid #eee;
    color: #eee;
}

.icard {
    text-align: left;
    padding: 1rem;
}

.icard:hover {
    background-color: #141414;
}

.icard h3 {
    font-size: 1.2rem;
    padding: 1rem;
}

.icard .barbottom {
    border-bottom: 2px solid #ffff;
    width: 100%;
    margin: 1rem auto;
    display: block;
}

.icard .bar {
    border-bottom: 2px solid #febe00;
    width: 40%;
    margin: 1rem auto;
    display: block;
}

.icard .label {
    font-size: 1.8rem;
    font-weight: 500;
    padding: 0.4rem;
    color: #febe00
}

.icard .pickup {
    font-size: 1.2rem;
    font-weight: 250;
    padding: 0rem;
    color: #febe00;
}

.insights p {
    color: #eee;
    padding: 1rem 0;
    font-size: 1rem;
    text-align: left;
}

.insights p1 {
    color: #eee;
    padding: 1rem 0;
    font-size: 1rem;
    font-weight: 250;
}

.icard .btn {
    display: block;
    width: auto;
    margin: 0rem auto;
    color: #febe00;
    text-align: center;
}

@media screen and (max-width: 980px) {
    .icard-container {
        max-width: 90%;
        margin: auto;
        grid-template-columns: 1fr;
    }
}



